<template>
  <div v-if="filePath">
    <a @contextmenu.prevent @click.prevent="getPdf(filePath)" href="#" draggable="false" class="p-link pdf-img-link">
      <img src="/assets/images/pdf_md_icon.png" draggable="false"/>
      <!--                      <div>{{ orderData.sales_invoice.number }}</div>-->
    </a>
  </div>
</template>

<script>
import httpClient from "@/services/http.services";
import httpMixins from "@/mixins/httpMixins";
import settings from "@/settings";

export default {
  mixins: [ httpMixins ],
  name: 'GetPdfButton',
  props: {
    filePath: String
  },
  methods: {
    async getPdf(filePath) {
      if (!filePath) return false
      try {
        const { status, data } = await httpClient(`sales-invoice/get-pdf?filePath=${filePath}`, {responseType: 'arraybuffer'})
        // const { status, data } = await httpClient(`offer/get-pdf?filePath=${item?.file_path}`, {responseType: 'blob'})
        if (status === 200 && data) {

          const blob = new Blob([data], { type: 'application/pdf' })
              //, url = URL.createObjectURL(blob)
          const url = URL.createObjectURL(blob)

          window.open(url, '_blank')
          URL.revokeObjectURL(url);
        }
      } catch(err) {
        // this.showError(err)
        this.$toast.add({severity:'error', summary: `404`, detail: 'File not found', life: settings.toastLife})
      }
      // this.isLoading = false
    },
  }
}
</script>

<style lang="scss" scoped>
.pdf-img-link {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  //display: flex;
  img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 50px;
  }
}

</style>